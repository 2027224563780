<template>
    <!--static-->
    <b-modal
            :id="PREFIX + '-modal'"
            hide-footer
            @hide="resetForm()"
            @shown="initData()"

    >
        <template #modal-title>
            {{action == 'editing'?$t('label_chat'):$t('label_chat')}}
        </template>
        <template #default="{ hide }">


            <validation-observer
                    tag="form"
                    class="p-1"
                    #default="{handleSubmit, reset, invalid}"
                    :ref="PREFIX + '_FORM'"

            >

                <validation-provider
                        #default="validationProps"
                        :name="$t('label_chat_name')"
                        rules="required"
                        slim

                >
                    <b-form-group
                            :label="$t('label_chat_name')"
                    >
                        <b-form-input
                                v-model="itemData.name"
                                :state="getValidationState(validationProps)"
                                :placeholder="$t('label_subject')"
                        />

                        <b-form-invalid-feedback :state="getValidationState(validationProps)">
                            {{ validationProps.errors[0] }}
                        </b-form-invalid-feedback>
                    </b-form-group>
                </validation-provider>

                    <div class="" v-if="action != 'adding' && chatUsers.length > 0">

                            <b-form-group
                                    :label="$t('label_chat_user').ucFirst()"
                                    label-for="fake"
                            >
                            <table class="table table-striped" id="chat_users_table">
                                <!--<? foreach ($chat_user as $user) { ?>-->
                                <tr v-for="user in chatUsers" class="user-box" >
                                    <td>
                                        <b-avatar
                                                size="40"
                                                :src="user.profile_picture? $domain + user.profile_picture : ''"
                                        />

                                    </td>
                                    <td>
                                        {{user.name}}
                                    </td>
                                    <td>

                                        <b-button size="sm" icon variant="outline-danger" v-if="itemData.from_user_id != user.id && user.id != 1"  class=""
                                                @click="removeChatUser(itemData.id, user.id)">
                                            <!--{{$t('label_delete')}}-->
                                            <feather-icon icon="TrashIcon"></feather-icon>
                                        </b-button>

                                    </td>
                                </tr>

                            </table>
                            </b-form-group>
                    </div>

                <validation-provider
                        #default="validationProps"
                        :name="$t('label_add_user')"
                        :rules="(action == 'adding' || action == 'editing' && chatUsers.length < 1) ? 'required' : ''"
                        slim
                >
                    <b-form-group
                            :label="$t('label_add_user').ucFirst()"
                            label-for="fake"
                    >

                        <infinity-scroll
                                v-model="itemData.chat_user"
                                selected-prop="id"
                                url="/user"
                                :query-params="{exclude_type:[4], exclude_id: action == 'editing'? expectUsersIds : [1]}"
                                :multiple="true"
                                :closeOnSelect="false"
                                :placeholder="$t('label_select')"

                        >
                            <template #label="{item}">{{item.name}}</template>

                        </infinity-scroll>

                        <b-form-invalid-feedback :state="getValidationState(validationProps)">
                            {{ validationProps.errors[0] }}
                        </b-form-invalid-feedback>
                    </b-form-group>
                </validation-provider>

                <validation-provider
                        class="mt-2"
                        :name="$t('label_icon')"
                        slim
                        #default="validationProps"

                >
                    <b-form-group
                            :label="$t('label_icon').ucFirst()"
                    >
                    <file-input
                            v-model="document"
                            class="file--uploader drop-block mb-3"
                            :max-files-count='10'
                            :required="true"
                            :multiple="false"
                            allowed-extensions="*"
                            :max-file-size='100'
                            :url="''"
                    >
                        <template #uploadBody>
                            <feather-icon class="mr-1" data-toggle icon="DownloadCloudIcon" size="24"/>
                            <!--<img :src="require('@/assets/images/upload.svg')" class="mr-1">-->
                            {{$t('label_add_file')}}
                        </template>

                        <template #errorBug="fileErrors">

                            <span v-if="validationProps.failedRules.length > 0 || fileErrors.errors.length > 0">{{validationProps.failedRules.length  > 0 ?  validationProps.errors[0] : fileErrors.errors[0] }}</span>
                            <b-form-invalid-feedback :state="validationProps.failedRules.length > 0">
                                {{ validationProps.errors[0] }}
                            </b-form-invalid-feedback>
                        </template>
                    </file-input>
                    </b-form-group>
                </validation-provider>


                <div v-if="itemData.icon">

                        <img :src="$domain + chat.icon" width="75px">
                    <feather-icon icon="TrashIcon" @click="deleteIcon(itemData.id)"></feather-icon>

                </div>


                <div class="d-flex justify-content-end mt-2">
                    <button v-if="action == 'editing'" @click.prevent="$emit('delete-item',itemData.id)" class=" btn btn-danger mr-auto">
                        {{$t('label_delete')}}
                    </button>
                    <button @click.prevent="$bvModal.hide(PREFIX + '-modal')" type="reset" class=" btn btn-default">
                        {{$t('label_close')}}
                    </button>

                    <button @click.prevent="handleSubmit(onSubmit);focusOnFormError(invalid)" type="submit"
                            class="btn btn-primary waves-effect waves-light">{{(action ==
                        'editing')?$t('label_edit'):$t('label_add')}}
                    </button>
                </div>

            </validation-observer>

        </template>

    </b-modal>
</template>


<script>
    import {ValidationProvider, ValidationObserver} from 'vee-validate'

    import {
        BFormTextarea,
        BFormGroup,
        BFormInput,
        BFormInvalidFeedback,
        BInputGroupAppend,
        BInputGroup,
        BFormCheckbox,
        BInputGroupPrepend, BAvatar, BButton,
    } from 'bootstrap-vue'

    import  infinityScroll from '@/views/components/infinityScroll'
    import  fileInput from '@/views/components/fileUploadInput'
    import {serialize} from 'object-to-formdata';
    import {CHAT_PREFIX as PREFIX} from './../moduleHelper'

    export default {
        components: {

            BFormGroup,
            BFormInput,
            BFormInvalidFeedback,
            BFormTextarea,
            BInputGroup,
            BInputGroupAppend,
            BFormCheckbox,
            BInputGroupPrepend, BAvatar, BButton,

            ValidationProvider,
            ValidationObserver,
            infinityScroll,
            fileInput
            // vSelect
        },

        props: ['editedItem'],

        data() {
            return {

                PREFIX,

                action: 'adding',

                blankItemData: {
                    name: '',
                    chat_user: []
                },

                itemData: {},

                document: [],

                expectUsersIds: [],

                chatUsers: []


            }
        },

        watch: {
            moduleItem: function (moduleItem) {
                if (moduleItem) {
                    this.MODULE_PREFIX = this.isDeal ? this.DEAL_PREFIX : this.AGREEMENT_PREFIX;
                    this.blankItemData['agreement_id'] = this.isDeal ? moduleItem.agreement_id : moduleItem.id;
                }
            }
        },

        methods: {
            initData() {

                if (this.editedItem) {
                    this.action = 'editing';
                    let item = Object.assign({}, this.blankItemData);
                    item.id = this.editedItem.id;

                    this.chatUsers = this.editedItem.users;
                    this.expectUsersIds = this.chatUsers.pluck('id').push(this.authUser().id, 1);

                    for (let prop in item) {
                        if (this.editedItem.hasOwnProperty(prop)) {
                            item[prop] = this.editedItem[prop];
                        }
                    }


                    this.itemData = item;

                } else {
                    this.action = 'adding';
                    this.itemData = Object.assign({}, this.blankItemData);

                }
            },
            resetForm() {
                this.itemData = Object.assign({}, this.blankItemData);
                this.chatUsers = [];
                this.document = [];
                this.$refs[this.PREFIX + '_FORM'].reset();
            },

            onSubmit() {

                let formData = new FormData();
                formData = serialize(this.itemData, {}, formData);
                formData.append('icon', this.document[0]);

                if (this.action == 'adding') {
                    this.async('post', '/' + this.PREFIX, formData, function (resp) {
                        this.$emit('item-added', resp.data.item);
                        this.$bvModal.hide(this.PREFIX + '-modal');
                    },false,  {headers:{'Content-Type': 'multipart/form-data'}});
                } else {
                    this.async('put', '/' + this.PREFIX + '/' + formData.get('id'), formData, function (resp) {
                        this.$emit('item-edited', {});
                        this.$bvModal.hide(this.PREFIX + '-modal');
                    },false,  {headers:{'Content-Type': 'multipart/form-data'}});

                }

            },
            deleteIcon(id){
                this.confirmDeleting((result) => {

                    this.async('delete','/' + this.MODULE_PREFIX + '_' + this.PREFIX + 's/' + id, {params: {}}, function (resp) {
                        this.refreshDataTable();
                    });
                })
            },

            removeChatUser(chat_id, user_id){
                this.confirmDeleting((result) => {

                    this.async('delete','/chat_users/' + chat_id, {params: {user_id: user_id}}, function (resp) {
                        this.chatUsers = this.chatUsers.filter((item)=> item.id != user_id);
                    });
                })
            }
        },

    }
</script>
