<template>
  <div
    class="user-profile-sidebar"
    :class="{'show': shallShowUserDetailsSidebar}"
  >
    <!-- Header -->
    <header
      v-if="user"
      class="user-profile-header"
    >
      <span class="close-icon">
        <feather-icon
          icon="XIcon"
          @click="$emit('update:shall-show-user-details-sidebar', false)"
        />
      </span>

      <div class="header-profile-sidebar">
        <div class="avatar box-shadow-1 avatar-xl avatar-border">
          <b-avatar
            size="70"
            :src="user.profile_picture? $domain + user.profile_picture : ''"
          />

        </div>
        <h4 class="chat-user-name">{{ user.name }}</h4>

      </div>
    </header>

    <!-- User Details -->
    <vue-perfect-scrollbar
      :settings="perfectScrollbarSettings"
      class="user-profile-sidebar-area scroll-area"
    >

      <!-- Personal Info -->
      <div class="personal-info">
        <h6 class="section-label mb-1 mt-3">{{ $t('label_personal_info')}}</h6>
        <ul class="list-unstyled">
          <li class="mb-1">
            <feather-icon
              icon="MailIcon"
              size="16"
              class="mr-75"
            />
            <span class="align-middle">{{user.emailAddress}}</span>
          </li>
          <li class="mb-1" v-if="user.phone">
            <feather-icon
              icon="PhoneCallIcon"
              size="16"
              class="mr-75"
            />
            <span class="align-middle">{{user.phone}}</span>
          </li>

        </ul>
      </div>

      <!-- More Options -->
      <!--2 -agent // 3 -solicitor-->

      <div class="more-options" v-if="user.userType == 2 || user.userType == 3">
      <!--<div class="more-options">-->

        <h6 class="section-label mb-1 mt-3">{{$t('label_options')}}</h6>
        <ul class="list-unstyled" v-if="user.userType == 2">
          <li class="cursor-pointer mb-1">
            <span>{{$t('label_department')}}: </span>
            <span class="align-middle">{{userTypeData.department}}</span>
          </li>
          <li class="cursor-pointer mb-1">
            <span>{{$t('label_manager')}}: </span>
            <span class="align-middle">{{userTypeData.manager_name}}</span>
          </li>
          <li class="cursor-pointer mb-1">
            <span>{{$t('label_keeper')}}: </span>
            <span class="align-middle">{{userTypeData.consultant_name}}</span>
          </li>
          <li class="cursor-pointer mb-1">
            <span>{{$t('label_country')}}: </span>
            <span class="align-middle">{{userTypeData.countryName}}</span>
          </li>
          <li class="cursor-pointer mb-1">
            <span>{{$t('label_town')}}: </span>
            <span class="align-middle">{{userTypeData.town}}</span>
          </li>
          <li class="cursor-pointer mb-1">
            <span>{{$t('label_street_and_house_number')}}: </span>
            <span class="align-middle">{{userTypeData.street}}</span>
          </li>
          <li class="cursor-pointer mb-1">
            <span>{{$t('label_post_code')}}: </span>
            <span class="align-middle">{{userTypeData.zip}}</span>
          </li>
          <li class="cursor-pointer mb-1">
            <span>{{$t('label_company')}}: </span>
            <span class="align-middle">{{ $t(userTypeData.company == 'Y' ? 'label_yes' : 'label_no')}}</span>
          </li>
          <li class="cursor-pointer mb-1">
            <span>{{$t('label_name_of_company')}}: </span>
            <span class="align-middle">{{userTypeData.company_name}}</span>
          </li>
          <li class="cursor-pointer mb-1">
            <span>{{$t('label_nip')}}: </span>
            <span class="align-middle">{{userTypeData.NIP}}</span>
          </li>
          <li class="cursor-pointer mb-1">
            <span>{{$t('label_krs')}}: </span>
            <span class="align-middle">{{userTypeData.KRS}}</span>
          </li>
          <li class="cursor-pointer mb-1">
            <span>{{$t('label_account_number')}}: </span>
            <span class="align-middle">{{userTypeData.account_number}}</span>
          </li>
          <li class="cursor-pointer mb-1">
            <span>{{$t('label_bank_name')}}: </span>
            <span class="align-middle">{{userTypeData.bank_name}}</span>
          </li>
          <li class="cursor-pointer mb-1">
            <span>{{$t('label_vat_payer')}}: </span>
            <span class="align-middle">{{$t(userTypeData.vat_payer == 'Y' ? 'label_yes' : 'label_no')}}</span>
          </li>
          <li class="cursor-pointer mb-1">
            <span>{{$t('label_vat_payer_description')}}: </span>
            <span class="align-middle">{{userTypeData.vat_payer_txt}}</span>
          </li>
          <li class="cursor-pointer mb-1">
            <span>{{$t('label_date_of_signing_agreement')}}: </span>
            <span class="align-middle">{{formatDate(userTypeData.agreement_date, 'DD.MM.YYYY', 'unix')}}</span>
          </li>
          <li class="cursor-pointer mb-1">
            <span>{{$t('label_id_of_partner')}}: </span>
            <span class="align-middle">{{userTypeData.id_of_partner}}</span>
          </li>
          <li class="cursor-pointer mb-1">
            <span>{{$t('label_see_section_instruction')}}: </span>
            <span class="align-middle">{{$t(userTypeData.see_instruction == 'Y' ? 'label_yes' : 'label_no')}}</span>
          </li>
          <li class="cursor-pointer mb-1">
            <span>{{$t('label_formulars')}}: </span>
            <span class="align-middle">{{$t(userTypeData.formulars == 'Y' ? 'label_yes' : 'label_no')}}</span>
          </li>
          <li class="cursor-pointer mb-1">
            <span>{{$t('label_training_script')}}: </span>
            <span class="align-middle">{{$t(userTypeData.training_script == 'Y' ? 'label_yes' : 'label_no')}}</span>
          </li>

        </ul>
        <ul class="list-unstyled" v-if="user.userType == 3">

          <li class="cursor-pointer mb-1">
            <span>{{$t('label_department')}}: </span>
            <span class="align-middle">{{userTypeData.department}}</span>
          </li>
          <li class="cursor-pointer mb-1">
            <span>{{$t('label_solicitor_above')}}: </span>
            <span class="align-middle">{{userTypeData.above_solicitor}}</span>
          </li>
          <li class="cursor-pointer mb-1">
            <span>{{$t('label_country')}}: </span>
            <span class="align-middle">{{userTypeData.countryName}}</span>
          </li>
          <li class="cursor-pointer mb-1">
            <span>{{$t('label_city')}}: </span>
            <span class="align-middle">{{userTypeData.city}}</span>
          </li>
          <li class="cursor-pointer mb-1">
            <span>{{$t('label_street_and_house_number')}}: </span>
            <span class="align-middle">{{userTypeData.street}}</span>
          </li>
          <li class="cursor-pointer mb-1">
            <span>{{$t('label_post_code')}}: </span>
            <span class="align-middle">{{userTypeData.zip}}</span>
          </li>
          <li class="cursor-pointer mb-1">
            <span>{{$t('label_invoice_details')}}: </span>
            <span class="align-middle">{{userTypeData.invoice_details}}</span>
          </li>
          <li class="cursor-pointer mb-1">
            <span>{{$t('label_company_name')}}: </span>
            <span class="align-middle">{{userTypeData.company_name}}</span>
          </li>
          <li class="cursor-pointer mb-1">
            <span>{{$t('label_nip')}}: </span>
            <span class="align-middle">{{userTypeData.NIP}}</span>
          </li>
          <li class="cursor-pointer mb-1">
            <span>{{$t('label_iban')}}: </span>
            <span class="align-middle">{{userTypeData.IBAN}}</span>
          </li>
          <li class="cursor-pointer mb-1">
            <span>{{$t('label_swift')}}: </span>
            <span class="align-middle">{{userTypeData.SWIFT}}</span>
          </li>
          <li class="cursor-pointer mb-1">
            <span>{{$t('label_bank_name')}}: </span>
            <span class="align-middle">{{userTypeData.bank_name}}</span>
          </li>
          <li class="cursor-pointer mb-1">
            <span>{{$t('label_account_number')}}: </span>
            <span class="align-middle">{{userTypeData.account_number}}</span>
          </li>
          <li class="cursor-pointer mb-1">
            <span>{{$t('label_hourly_rate')}}: </span>
            <span class="align-middle">{{userTypeData.hourly_rate}}</span>
          </li>
        </ul>
      </div>
    </vue-perfect-scrollbar>
  </div>
</template>

<script>
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import { BAvatar } from 'bootstrap-vue'

export default {
  components: {
    // BSV
    BAvatar,

    // 3rd Party
    VuePerfectScrollbar,
  },
  props: {
      shallShowUserDetailsSidebar: {
      type: Boolean,
      required: true,
    },
    user: {
      type: Object,
      required: true,
    },
  },
    data() {
        return {

            // PREFIX,


            perfectScrollbarSettings: {
                maxScrollbarLength: 150,
            },
            // searchQuery:'',

            userTypeData: {},


        }
    },
    watch:{
          user: function(newVal, oldVa){
              if(!this.shallShowUserDetailsSidebar) return;
              this.getFullUserData(newVal);

          }
    },
    methods:{
       getFullUserData(user){

           if(!user) return;

           let userType = user.userType;
           if(userType != 3 && userType != 2) return;

           let userTypeName = '';
           switch(userType){
               case 2: userTypeName = 'solicitor'; break;
               case 3: userTypeName = 'agent'; break;
           }

           this.async('get', '/' + userTypeName + '/' + user.id, {}, function(res){
               this.userTypeData = res.data[userTypeName+'_data'];
           });
       }
    },
    created(){
        this.getFullUserData(this.user);
    }
}
</script>

<!--<style>-->

<!--</style>-->
