<template>


    <div style="height: inherit">


        <div
                class="body-content-overlay"
                :class="{'show': shallShowUserDetailsSidebar || mqShallShowLeftSidebar}"
                @click="mqShallShowLeftSidebar=shallShowUserDetailsSidebar=false"
        />

        <section class="chat-app-window">


            <!-- Start Chat Logo -->
            <div class="start-chat-area" v-if="!activeChat">

                <template v-if="no_chats">
                    <div class="mb-1 start-chat-icon">
                        <feather-icon
                                icon="MessageSquareIcon"
                                size="56"
                        />
                    </div>
                    <h4
                            class="sidebar-toggle start-chat-text"
                            @click="editedChat = false; $bvModal.show(PREFIX + '-modal'); "
                    >
                        {{$t('label_start_conversation')}}
                    </h4>
                </template>

                <b-spinner v-else variant="primary"/>

            </div>


            <!-- Chat Content -->

            <div class="active-chat">
                <!-- **********************Chat Navbar HEADER************************-->
                <div class="chat-navbar">
                    <header class="chat-header">

                        <!-- Avatar & Name -->
                        <div class="d-flex align-items-center">

                            <!-- Toggle Icon -->
                            <div class="sidebar-toggle d-block d-lg-none mr-1">
                                <feather-icon
                                        icon="MenuIcon"
                                        class="cursor-pointer"
                                        size="21"
                                        @click="mqShallShowLeftSidebar = true"
                                />

                            </div>

                            <div class="avatar box-shadow-1 avatar-xl avatar-border mr-1">


                                <b-avatar v-if="activeChat.icon && !chatIniting" :src="$domain + activeChat.icon" button @click="$bvModal.show(PREFIX + '-modal'); editedChat = activeChat" size="36" class="cursor-default"></b-avatar>
                                <b-avatar v-else  button @click="$bvModal.show(PREFIX + '-modal'); editedChat = activeChat" size="36" class="cursor-default">
                                    <div v-if="chatIniting">
                                        <b-spinner
                                                variant="primary"
                                        />
                                    </div>
                                    <feather-icon v-else size="20" icon="MessageCircleIcon"></feather-icon>
                                </b-avatar>
                            </div>
                            <h6 class="mb-0">
                                {{ activeChat.name }}
                            </h6>
                        </div>

                    </header>
                </div>

                <!-- User Chat Area -->

                <vue-perfect-scrollbar

                        ref="msg-scroller"
                        :settings="perfectScrollbarSettings"
                        class="user-chats scroll-area"
                >
                    <div class="chats" v-if="activeChat">
                        <div
                                v-for="(msg, index) in activeChat_messages"
                                :key="msg.id"
                                class="chat"
                                :class="{'chat-left': msg.from_user_id !== authUser().id}"
                        >

                            <div @click="processActiveUser(msg.from_user)"
                                 class="chat-avatar cursor-pointer"
                                 v-if="index==0 || activeChat_messages[index-1].from_user_id != activeChat_messages[index].from_user_id ">
                                <b-avatar v-if="activeChat_messages[index].from_user.profile_picture"
                                        size="36"
                                        class="avatar-border-2 box-shadow-1"
                                        variant="transparent"
                                        :src="$domain+activeChat_messages[index].from_user.profile_picture"

                                />
                                <b-avatar v-else
                                          size="36"
                                          class=""
                                          variant="secondary"

                                > <feather-icon size="20" icon="UserIcon"></feather-icon></b-avatar>
                            </div>

                            <div class="chat-body">
                                <div :class="msg.from_user_id !== authUser().id? 'left-msg' : 'right-msg'">
                                    <span class="u-name"
                                          v-if="index==0 || activeChat_messages[index-1].from_user_id != activeChat_messages[index].from_user_id ">{{(msg.from_user ? msg.from_user.name : $t('label_deleted'))}} <br></span>
                                    <span class="badge badge-light-success">{{formatDate(msg.posted_at, 'DD MMM HH:mm', 'YYYY-MM-DD HH:mm:ss')}}</span>
                                    <span v-if="msg.views">
                                        <span class="badge badge-light-success">
                                            <feather-icon icon="EyeIcon"></feather-icon> {{msg.views.seen_count}}
                                        </span>
                                    </span>
                                </div>
                                <div class="chat-content">
                                    <p>{{ msg.message }}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </vue-perfect-scrollbar>

                <!-- Message Input -->
                <b-form v-if="activeChat"
                        class="chat-app-form"
                        @submit.prevent="sendMessage"
                >
                    <b-input-group class="input-group-merge form-send-message mr-1">
                        <b-form-input
                                v-model="chatInputMessage"
                                :placeholder="$t('label_enter_your_message')"
                        />
                    </b-input-group>
                    <b-button
                            variant="primary"
                            type="submit"
                    >
                        {{$t('label_send')}}
                    </b-button>
                </b-form>
            </div>
        </section>


        <chat-user-details-sidebar
                :shall-show-user-details-sidebar.sync="shallShowUserDetailsSidebar"
                :user="activeUser || {}"
        />


        <portal to="content-renderer-sidebar-left">
            <chat-left-sidebar
                    :chats="chats"
                    :active-chat-contact-id="activeChat ? activeChat.id : null"
                    :mq-shall-show-left-sidebar.sync="mqShallShowLeftSidebar"
                    @open-chat="openNewChat($event)"
                    @add-chat="editedChat = false; $bvModal.show(PREFIX + '-modal'); "
            />
        </portal>

        <process-chat-modal @item-added="chatAdded($event)" :editedItem="editedChat" @delete-item="deleteChat($event)"></process-chat-modal>

    </div>
</template>

<script>
    import store from '@/store'

    import {
        BAvatar, BDropdown, BDropdownItem, BForm, BInputGroup, BFormInput, BButton, BSpinner
    } from 'bootstrap-vue'

    import VuePerfectScrollbar from 'vue-perfect-scrollbar'

    import {$themeBreakpoints} from '@themeConfig'
    import {useResponsiveAppLeftSidebarVisibility} from '@core/comp-functions/ui/app'
    import ChatLeftSidebar from './ChatLeftSidebar.vue'
    import ChatUserDetailsSidebar from './modals/ChatUserDetailsSidebar.vue'

    import processChatModal from './modals/processChatModal.vue'
    import {CHAT_PREFIX as PREFIX} from './moduleHelper'

    export default {
        components: {


            BAvatar,
            BDropdown,
            BDropdownItem,
            BForm,
            BInputGroup,
            BFormInput,
            BButton,
            BSpinner,

            VuePerfectScrollbar,

            ChatUserDetailsSidebar,
            ChatLeftSidebar,
            processChatModal

        },
        data() {
            return {
                PREFIX,

                perfectScrollbarSettings: {
                    maxScrollbarLength: 150,
                },

                shallShowUserProfileSidebar: false,
                shallShowUserDetailsSidebar: false,

                chatInputMessage: '',

                chats: [],
                activeChat: false,
                activeChat_messages: [],

                chatIntervalId: false,
                scrolledToBottom: false,
                chatIniting: true,
                lastChatUpdateIndex: null,
                activeUser: false,
                no_chats: false,

                editedChat: false
            }
        },
        beforeDestroy() {
            clearInterval(this.chatIntervalId);
        },
        methods: {

            resolveAvatarBadgeVariant() {

            },

            processActiveUser(user){
                            if(user.id == 1) return;
                this.activeUser = user;
                // if(user.userType == 3){
                    this.shallShowUserDetailsSidebar = !this.shallShowUserDetailsSidebar;
                // }
            },
            scrollToBottomInChatLog() {

                let self = this;
                this.$nextTick(function () {
                    const scrollEl = self.$refs['msg-scroller'];
                    if (scrollEl.$el) {
                        scrollEl.$el.scrollTop = scrollEl.$el.scrollHeight;
                    }

                });

            },
            openNewChat(chat) {
                this.chatIniting = true;
                let ind = Date.now();
                this.lastChatUpdateIndex = ind;
                this.activeChat = chat;
                this.chatInputMessage = '';
                clearInterval(this.chatIntervalId);
                this.scrolledToBottom = false;
                this.getChatDataByInterval(chat.id, ind);

            },

            sendMessage() {
                if (!this.chatInputMessage) return;
                this.scrolledToBottom = false;
                this.async('post', '/chat_message', {
                    message: this.chatInputMessage,
                    chat_id: this.activeChat.id
                }, function (res) {
                    this.chatInputMessage = '';

                });
            },

            getChatDataByInterval(chatId, updateInd) {
                let self = this;
                // this.lastChatUpdateIndex = false;

                this.chatIntervalId = setInterval(function () {

                    self.async('get', '/chat/' + chatId + '?after=0', {}, function (res) {
                        if (this.lastChatUpdateIndex && this.lastChatUpdateIndex != updateInd) {
                            return;
                        } else {
                            this.lastChatUpdateIndex = null;
                        }

                        this.activeChat_messages = res.data.messages;
                        // self.activeChat = res.data;
                        if (!this.scrolledToBottom) {
                            this.scrolledToBottom = true;

                            this.scrollToBottomInChatLog();
                        }
                        this.chatIniting = false;

                    });

                    self.async('get', '/chat', {}, function (res) {
                        if (this.lastChatUpdateIndex && this.lastChatUpdateIndex != updateInd) {
                            return;
                        } else {
                            this.lastChatUpdateIndex = null;
                        }

                        self.chats = res.data;
                        self.activeChat = res.data.findWhere('id',chatId);

                    });

                }, 1500);


            },
            openAnyChatIfExists(){

                if(this.chatIntervalId)
                clearInterval(this.chatIntervalId);

                this.async('get', '/chat', {}, function (res) {
                    if (res.data.length <1) {
                        this.activeChat = false;
                        this.chats = [];
                        this.no_chats = true;
                        return;
                    }
                    let lastChatId = res.data[res.data.length - 1].id;
                    if (lastChatId) {
                        this.getChatDataByInterval(lastChatId);
                        this.no_chats = false;
                    } else {
                        this.no_chats = true;
                    }

                });
            },
            deleteChat(id) {
                this.confirmDeleting((result) => {

                    this.async('delete','/' + this.PREFIX  + '/' + id, {params: {}}, function (resp) {

                        this.$bvModal.hide(PREFIX + '-modal');
                        if(this.activeChat &&  this.activeChat.id == id){

                            this.openAnyChatIfExists();
                        }


                    });
                })
            },
            chatAdded(newChat){
                this.activeChat = false;
                this.openAnyChatIfExists();
            }
        },
        setup() {

            const {mqShallShowLeftSidebar} = useResponsiveAppLeftSidebarVisibility();

            const startConversation = () => {
                if (store.state.app.windowWidth < $themeBreakpoints.lg) {
                    mqShallShowLeftSidebar.value = true
                }
            }

            return {
                startConversation,
                mqShallShowLeftSidebar,
            }
        },
        created() {

            let lastChatId = 0;
            this.openAnyChatIfExists();

        }
    }
</script>


<style lang="scss">
    @import "~@core/scss/base/pages/app-chat.scss";
    @import "~@core/scss/base/pages/app-chat-list.scss";
    .chat-application .chat-app-window .user-chats{
        background-color: #fdfdfd;
        background-image:none;
    }
    .start-chat-area{
        background-image:none !important;
    }
</style>
